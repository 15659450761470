import axios from 'axios';
import { navigate } from 'gatsby';
import React, {
  ChangeEventHandler,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import Button from '../components/Button';
import Card from '../components/Card';
import Layout from '../components/Layout';
import OverlayBox from '../components/OverlayBox';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { UserFM } from '@bm-js/h2o-shared';
import { ActionType } from '../types/dispatch.types';

const StyledMyAccount = styled.div`
  .single-customer-inner {
    display: flex;
    .single-customer-left-content {
      width: 60%;
      margin-right: 1.5rem;
    }
    .single-customer-right-content {
      width: calc(40% - 1.5rem);
    }
  }
  form {
    p {
      margin-bottom: 0.5rem !important;
    }
  }
  .avatar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .avatar-image-container {
      width: 300px;
      height: 300px;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.1);
      margin-bottom: 1rem;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        &.error {
          object-fit: contain;
        }
      }
    }
  }
  #avatarFileUpload {
    display: none;
  }

  .confirm-overlay-box {
    button {
      margin-right: 1rem;
    }
  }
  @media (max-width: 1000px) {
    .single-customer-inner {
      flex-direction: column;
      .single-customer-left-content {
        width: 100%;
      }
      .single-customer-right-content {
        width: 100%;
      }
    }
  }
  .confirm-overlay-box {
    button {
      margin-right: 0.5rem;
    }
    .customer-card-header {
      button {
        margin-right: 0;
      }
    }
  }
`;

const MyAccount = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const [changePasswordFormData, setChangePasswordFormData] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordRepeat: '',
  });

  const [confirmChange, setConfirmChange] = useState({
    active: false,
    proceed: () => {},
  });

  const onChangeUserInfo: ChangeEventHandler<HTMLInputElement> = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const updateUserInfo = () => {
    // e.preventDefault()
    dispatch({ type: ActionType.LOADING, value: true });
    const cleanedFormData = {
      firstName: userInfo.firstName.trim(),
      lastName: userInfo.lastName.trim(),
      email: userInfo.email.trim(),
      phone: userInfo.phone,
    };
    axios
      .post(
        `${process.env.GATSBY_BACKEND_API_URL}users/auth/self-update/${(state.user as UserFM)._id}`,
        { user: cleanedFormData },
        {
          headers: { Authorization: 'Bearer ' + state.token },
        }
      )
      .then(() => {
        dispatch({ type: ActionType.ALERT, content: 'Användare sparad' });
        localStorage.removeItem('token');
        dispatch({ type: ActionType.LOGOUT });
        setTimeout(() => {
          navigate('/');
        }, 500);
      })
      .catch((err) => {
        dispatch({ type: ActionType.LOADING, value: false });
        //console.log(err.response)
        if (err.response.data === 'Email exists') {
          dispatch({
            type: ActionType.ALERT,
            content: 'Mailadressen finns redan',
          });
        } else {
          dispatch({ type: ActionType.ALERT, content: 'Något gick fel' });
        }
      });
  };

  const loadUser = () => {
    setUserInfo({
      firstName: (state.user as UserFM).firstName,
      lastName: (state.user as UserFM).lastName,
      email: (state.user as UserFM).email,
      phone: (state.user as UserFM).phone || '',
    });
    dispatch({ type: ActionType.LOADING, value: false });
  };

  const onChangePassword: ChangeEventHandler<HTMLInputElement> = (e) => {
    setChangePasswordFormData({
      ...changePasswordFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangePassword = () => {
    // e.preventDefault()
    dispatch({ type: ActionType.LOADING, value: true });
    if (
      changePasswordFormData.newPassword !==
      changePasswordFormData.newPasswordRepeat
    ) {
      dispatch({ type: ActionType.ALERT, content: 'Lösenorden matchar inte' });
      dispatch({ type: ActionType.LOADING, value: false });
      return;
    }
    const body = {
      user: {
        oldPassword: changePasswordFormData.oldPassword,
        newPassword: changePasswordFormData.newPassword,
      },
    };
    axios
      .post(
        `${process.env.GATSBY_BACKEND_API_URL}users/auth/change-password/${(state.user as UserFM)._id}`,
        body,
        {
          headers: { Authorization: 'Bearer ' + state.token },
        }
      )
      .then(() => {
        dispatch({ type: ActionType.ALERT, content: 'Lösenord ändrat' });
        setChangePasswordFormData({
          oldPassword: '',
          newPassword: '',
          newPasswordRepeat: '',
        });
        localStorage.removeItem('token');
        dispatch({ type: ActionType.LOGOUT });
        setTimeout(() => {
          navigate('/');
        }, 500);
      })
      .catch((err) => {
        //console.log(err.response)
        dispatch({ type: ActionType.LOADING, value: false });
        if (err.response.data.details) {
          if (err.response.data.details[0].path[0] === 'newPassword') {
            dispatch({
              type: ActionType.ALERT,
              content:
                'Lösenordet måste vara minst 10 tecken långt och innehålla både stora och små bokstäver, samt minst en siffra',
            });
          } else {
            dispatch({ type: ActionType.ALERT, content: 'Något gick fel' });
          }
        } else if (err.response.status === 401) {
          dispatch({ type: ActionType.ALERT, content: 'Fel lösenord' });
        } else {
          dispatch({ type: ActionType.ALERT, content: 'Något gick fel' });
        }
      });
  };

  useEffect(() => {
    dispatch({ type: ActionType.LOADING, value: true });

    if (state.token) {
      loadUser();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.token, state.user]);

  return (
    <Layout>
      <StyledMyAccount>
        <h1 className="page-header">Mitt konto</h1>
        <div className="single-customer-inner">
          <div className="single-customer-left-content">
            <Card>
              <div className="customer-card-header">
                <h3>Ändra uppgifter</h3>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setConfirmChange({
                    active: true,
                    proceed: () => {
                      updateUserInfo();
                    },
                  });
                }}
              >
                <input
                  required
                  type="text"
                  name="firstName"
                  value={userInfo.firstName}
                  onChange={onChangeUserInfo}
                  placeholder="Förnamn"
                />
                <input
                  required
                  type="text"
                  name="lastName"
                  value={userInfo.lastName}
                  onChange={onChangeUserInfo}
                  placeholder="Efternamn"
                />
                <input
                  required
                  type="text"
                  name="email"
                  value={userInfo.email}
                  onChange={onChangeUserInfo}
                  placeholder="E-post"
                />
                <input
                  type="text"
                  name="phone"
                  value={userInfo.phone}
                  onChange={onChangeUserInfo}
                  placeholder="Telefonnummer"
                />
                <p>
                  <strong>Roll:</strong> {(state.user as UserFM).role}
                </p>
                <Button med type="submit">
                  Spara
                </Button>
              </form>
            </Card>
            <Card>
              <div className="customer-card-header">
                <h3>Byt lösenord</h3>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setConfirmChange({
                    active: true,
                    proceed: () => {
                      handleChangePassword();
                    },
                  });
                }}
              >
                <input
                  required
                  type="password"
                  name="oldPassword"
                  value={changePasswordFormData.oldPassword}
                  onChange={onChangePassword}
                  placeholder="Nuvarande lösenord"
                />
                <input
                  required
                  type="password"
                  name="newPassword"
                  value={changePasswordFormData.newPassword}
                  onChange={onChangePassword}
                  placeholder="Nytt lösenord"
                />
                <input
                  required
                  type="password"
                  name="newPasswordRepeat"
                  value={changePasswordFormData.newPasswordRepeat}
                  onChange={onChangePassword}
                  placeholder="Upprepa nytt lösenord"
                />
                <Button med type="submit">
                  Spara
                </Button>
              </form>
            </Card>
          </div>
          <div className="single-customer-right-content"></div>
        </div>
        <OverlayBox
          className="confirm-overlay-box"
          active={confirmChange.active}
          header="Är du säker?"
          close={() =>
            setConfirmChange({
              active: false,
              proceed: () => {},
            })
          }
        >
          <p>
            När du sparar en ändring av din profilinformation så loggas du ut.
            <br />
            Vill du fortsätta?
          </p>
          <Button med onClick={confirmChange.proceed}>
            Fortsätt
          </Button>
          <Button
            med
            onClick={() =>
              setConfirmChange({
                active: false,
                proceed: () => {},
              })
            }
          >
            Tillbaka
          </Button>
        </OverlayBox>
      </StyledMyAccount>
    </Layout>
  );
};

export default MyAccount;
